export const InitialState = {
  hamburgerMenu: false,
  headerHeight: 0,
  role:"",
  specialistModalOpen: false,
};

export const actionTypes = {
  SET_HAMBURGER_MENU: "SET_HAMBURGER_MENU",
  SET_HEADER_HEIGHT: "SET_HEADER_HEIGHT",
  SET_ROLE: "SET_ROLE",
  SET_SPECIALIST_MODAL_OPEN: "SET_SPECIALIST_MODAL_OPEN",
};

export const Reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_HAMBURGER_MENU:
      if (document) {
        //maybe some action
      }
      return {
        ...state,
        hamburgerMenu: action.payload,
      };
    case actionTypes.SET_HEADER_HEIGHT:
      return {
        ...state,
        headerHeight: action.payload,
      };
    case actionTypes.SET_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    case actionTypes.SET_SPECIALIST_MODAL_OPEN:
      return {
        ...state,
        specialistModalOpen: action.payload,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
