import { Store } from "./src/redux/store";

export const wrapRootElement = Store;

if (
  process.env.NETLIFY_ENV === "production" ||
  process.env.NETLIFY_ENV === "development"
) {
  const originalConsoleError = console.error;

  console.error = function (...args) {
    const message = args[0];

    if (
      typeof message === "string" &&
      message.includes("Minified React error")
    ) {
      // Skip logging
      return;
    }

    originalConsoleError.apply(console, args);
  };
}
