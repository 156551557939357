exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-pipeline-js": () => import("./../../../src/pages/our-pipeline.js" /* webpackChunkName: "component---src-pages-our-pipeline-js" */),
  "component---src-pages-our-science-js": () => import("./../../../src/pages/our-science.js" /* webpackChunkName: "component---src-pages-our-science-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-sanity-page-templates-news-js": () => import("./../../../src/sanity-page-templates/news.js" /* webpackChunkName: "component---src-sanity-page-templates-news-js" */),
  "component---src-sanity-page-templates-team-member-js": () => import("./../../../src/sanity-page-templates/team-member.js" /* webpackChunkName: "component---src-sanity-page-templates-team-member-js" */)
}

